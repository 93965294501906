import { useSelector } from "react-redux";
import { styleObjectBlackButton } from "../common/StyleSeter"

function NewPagination(props: any) {
    const DD = useSelector((e: any) => e.domainDetails);
    return (

        <div>
            <ul id="pageNumberContent" className="pages">
                <li id="prev">
                    <a href="javascript:void(0);" className="disable" style={{ pointerEvents: "none" }}>Prev</a>
                </li>
                <li id="pageNumber">
                    <a href="javascript:void(0);" className="select"  style={styleObjectBlackButton(DD?.colorSchema, true)}>1</a>
                </li>
                <li id="next">
                    <a href="javascript:void(0);" className="disable" style={{ pointerEvents: "none" }}>Next</a>
                </li>
                <input type="text" id="goToPageNumber_1" maxLength={6} size={4} />
                <a id="goPageBtn_1"  style={styleObjectBlackButton(DD?.colorSchema, true)}>GO</a>
            </ul>
        </div>
    )
}
export default NewPagination
import React, { useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';


const ToastMessage = () => {
    return (
        <div>
            <ToastContainer />
        </div>
    )
}

export default ToastMessage